/* .btn-addTask {
  margin-top: 20px;
  float: right;
  border: none;
  background: #1a237e;
  color: white;
  padding: 10px 80px;
  border-radius: 5px;
  box-shadow: 5px 8px 15px #1a237e;
} */
.formTasks {
  display: flex;
  flex-direction: column;
}
.text-message {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  background-color: rgb(236, 253, 160);
  padding: 10px 20px;
}
/* .btn-addTask:hover {
  background: linear-gradient(to right, #434dbd -30%, rgb(23, 28, 99) 100%);
} */
/* .btn-addNewTask button {
  margin-top: 20px;
  border: none;
  background: #1a237e;
  color: white;
  outline: none;
  padding: 10px 40px;
  border-radius: 5px;
  box-shadow: 5px 8px 15px #1a237e;
}
.btn-addNewTask button:hover {
  background: linear-gradient(to right, #434dbd -30%, rgb(23, 28, 99) 100%);
} */
/* .btn-cancel {
  margin-top: 20px;
  float: right;
  border: none;
  background: rgb(255, 24, 24);
  color: white;
  padding: 10px 80px;
  border-radius: 5px;
  box-shadow: 5px 8px 15px rgb(255, 24, 24);
}
.btn-cancel:hover {
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -30%,
    rgb(255, 24, 24) 100%
  );
} */

.message-upload {
  font-size: 18px;
  font-weight: normal;
  color: #191c22;
}
.container-files {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.container-files label {
  width: 48%;
}

.input-file-upload {
  /* background: linear-gradient(to right, #434dbd -30%, rgb(23, 28, 99) 100%); */
  border-style: dashed solid;
  border-color: rgba(23, 28, 99, 0.829);
  color: #191c22;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin: 0 auto 0;
  min-height: 15px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 50%;
  margin: 10px 0;
  border-radius: 10px;
}
.input-file-upload .input-custom {
  border: 10000px solid transparent;
  cursor: pointer;
  font-size: 10000px;
  margin: 0;
  opacity: 0;
  outline: 0 none;
  padding: 0;
  position: absolute;
  right: -1000px;
  top: -1000px;
}
.container-links-zoom {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  justify-content: space-between;
}
.container-links-zoom label {
  width: 48%;
}
.container-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.container-links label {
  width: 48%;
}
button.swal2-cancel.btn.btn-danger {
  box-shadow: 5px 5px 8px rgb(255, 24, 24, 1);
  margin-right: 10px !important;
  background-color: rgb(255, 24, 24) !important;
  outline: none !important;
  border: none !important;
}
button.swal2-confirm.btn.btn-success {
  box-shadow: 5px 8px 8px #1a237e;
  background-color: #1a237e !important;
  outline: none !important;
  border: none !important;
}
@media (max-width: 425px) {
  .input-file-upload {
    font-size: 14px;
  }
  .message-upload {
    font-size: 14px;
  }
  h2#swal2-title {
    font-size: 22px !important;
  }
  div#swal2-html-container {
    font-size: 18px;
    color: red !important;
  }
}
.container-btns-addTask {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 768px) {
  .container-btns-addTask {
    display: flex;
    justify-content: center;
  }
  .btn-cancel {
    margin-top: 60px;
  }
}
/* BTN AGREGAR NUEVA TAREA */
.btn-addNewTask button {
  text-decoration: none;
  position: absolute;
  border: none;
  font-size: 14px;
  font-family: inherit;
  color: #fff;
  width: 150px;
  height: 3em;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(90deg, #434dbd, #1a237e, #323db1);
  background-size: 300%;
  border-radius: 30px;
  padding: 10 40px;
  z-index: 1;
  outline: none !important;
}
.btn-addNewTask button:hover {
  animation: ani 8s linear infinite;
  border: none;
}
@keyframes ani {
  0% {
    background-position: 0%;
  }
}
.btn-addNewTask button:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #434dbd, #1a237e, #323db1);
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
}

.btn-addNewTask button:hover::before {
  filter: blur(20px);
}

.btn-addNewTask button:active {
  background: linear-gradient(90deg, #434dbd, #1a237e, #323db1);
}
/*  BTN CANCELA ENVIO DE NUEVA TAREA */
.btn-cancel button {
  text-decoration: none;
  position: absolute;
  border: none;
  font-size: 14px;
  font-family: inherit;
  color: #fff;
  width: 150px;
  height: 3em;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgb(255, 24, 24),
    rgb(253, 72, 72),
    rgb(255, 102, 102),
    rgb(255, 24, 24)
  );
  background-size: 300%;
  border-radius: 30px;
  padding: 10 40px;
  z-index: 1;
  outline: none !important;
}
.btn-cancel button:hover {
  animation: ani 8s linear infinite;
  border: none;
}
@keyframes ani {
  0% {
    background-position: 0%;
  }
}
.btn-cancel button:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(
    90deg,
    rgb(255, 24, 24),
    rgb(253, 72, 72),
    rgb(255, 102, 102),
    rgb(255, 24, 24)
  );
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
}

.btn-cancel button:hover::before {
  filter: blur(20px);
}

.btn-cancel button:active {
  background: linear-gradient(
    90deg,
    rgb(255, 24, 24),
    rgb(253, 72, 72),
    rgb(255, 102, 102),
    rgb(255, 24, 24)
  );
}
/* BTN PARA AGREGAR NUEVA TAREA */
.btn-addTask button {
  text-decoration: none;
  position: absolute;
  border: none;
  font-size: 14px;
  font-family: inherit;
  color: #fff;
  width: 150px;
  height: 3em;
  line-height: 2em;
  text-align: center;
  margin-bottom: 60px;
  background: linear-gradient(90deg, #434dbd, #1a237e, #323db1);
  background-size: 300%;
  border-radius: 30px;
  padding: 10 40px;
  z-index: 1;
  outline: none !important;
}
.btn-addTask button:hover {
  animation: ani 8s linear infinite;
  border: none;
}
@keyframes ani {
  0% {
    background-position: 0%;
  }
}
.btn-addTask button:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #434dbd, #1a237e, #323db1);
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
}

.btn-addTask button:hover::before {
  filter: blur(20px);
}

.btn-addTask button:active {
  background: linear-gradient(90deg, #434dbd, #1a237e, #323db1);
}
