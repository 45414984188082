.navbar {
  padding: 10px 40px;
}
.bg-dark {
  background: linear-gradient(to right, #1a237e, rgb(13, 16, 56));
  /* background-color: #191c22 !important; */
}
.navbar-brand img {
  width: 40px;
  height: 40px;
}

.btn-dark {
  background-color: transparent !important;
  border: none;
  margin-right: 20px !important;
  padding: 10px 20px !important;
  outline: none;
}
.btn-dark:hover {
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -20%,
    rgb(255, 24, 24) 100%
  ) !important;
}
@media (max-width: 768px) {
  .navbar {
    padding: 10px 10px 10px 40px;
  }
}
@media (max-width: 425px) {
  .navbar {
    padding: 10px 10px;
  }
  .btn-dark {
    margin-right: 5px !important;
    padding: 5px !important;
  }
}
