.article-title h4 {
  font-size: 38px;
  margin-bottom: 80px;
  margin-top: 40px;
  color: #191c22;
}

.article-background {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.3);
}
.article-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.article-contain {
  height: 100%;
}
.article-contain p {
  font-size: 22px;
  line-height: 1.9;
  color: #191c22;
  margin-bottom: 10px;
}
.article-image {
  width: 300px;
  height: 250px;
}
.article-contain img {
  box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
img {
  width: 450px;
  height: 350px;
  float: left;
  margin-right: 40px;
  object-fit: cover;
}
.article-footer {
  margin-top: 40px;
  font-size: 16px;
  float: left;
  color: #838282;
}
.article-help {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.article-help a {
  margin-bottom: 20px;
  width: 60px;
}
.article-date {
  display: flex;
  margin-top: 10px;
}
.article-date p {
  font-size: 16px;
  color: #838282;
}
.article-date span {
  color: red;
  font-weight: bold;
}
.article-date img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.article-contain a {
  font-weight: bold;
  color: #1a237e;
  font-size: 22px;
  text-decoration: underline;
}
.container-loading {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.material-apoyo__link {
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .article-contain img {
    width: 40%;
    height: auto;
  }
}

@media (max-width: 425px) {
  .article-title h4 {
    margin-bottom: 10px;
  }
  .article-contain img {
    display: none;
  }
}

@media (max-width: 320px) {
  .article-background {
    height: 300px;
    width: 100%;
  }
  .article-background img {
    height: 300px;
    width: 100%;
  }
}
