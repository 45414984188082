
.welcome-title{
    display: none;
}
.welcome-subtitle{
    display: none;
}


@media screen and (max-width:768px){
    .welcome-title{
        display: block;
        font-weight: bold;
        color: #9791a0;
    }
    .welcome-subtitle{
        display: block;
        font-size: 22px;
        color: #9791a0;
    }
    
}
@media screen and (max-width:425px){
    .welcome-title{
      font-size: 32px;

    }
}
@media screen and (max-width:375px){
    .welcome-title{
      font-size: 28px;

    }
    .welcome-subtitle{
        font-size: 18px;
    }
    
}