.advice-contain p {
  font-weight: bold;
  font-size: 22px;
}
.advice-contain__icon {
  margin-top: 40px;
  height: 100%;
  width: 100%;
}
.advice-contain__icon a img {
  height: 180px;
  width: 18 0px;
}
form {
  margin-top: 40px;
}
label {
  font-weight: bold;
}
.notSubmitDay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  color: #979da7;
}
.notSubmitDay h4 {
  font-size: 28px;
  font-weight: bold;
}
.advice-button {
  float: right;
  border: none;
  background-color: rgb(255, 24, 24);
  border-radius: 5px;
  color: white;
  padding: 10px 80px;
  box-shadow: 5px 8px 15px rgb(255, 24, 24);
}
.advice-button:hover {
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -30%,
    rgb(255, 24, 24) 100%
  );
}
