html {
  height: 100%;
  overflow-x: hidden;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
}
h2 {
  margin: 20px 0 20px 0 !important;
}
.margin-top {
  margin-top: 0;
}
@media (max-width: 768px) {
  .margin-top {
    margin-top: -55px !important;
  }
}
@media (max-width: 425px) {
  .margin-top {
    margin-top: -75px !important;
  }
}

img {
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.swal2-styled.swal2-confirm {
  background-color: #1a237e !important;
  outline: none !important;
}
.swal2-styled.swal2-confirm:hover {
  background-color: #434dbd !important;
  outline: none !important;
}
.swal2-html-container {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #000000 !important;
  background-color: rgb(236, 253, 160);
  padding: 10px 20px;
}
