.startpage {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.startpage-img {
  width: 70%;
  height: 90vh;
}
.startpage-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.startpage-text {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.startpage-text h1 {
  margin: 0;
  color: #191c22;
  font-size: 48px;
  font-weight: bold;
}
.startpage-button button {
  margin-top: 40px;
  background: rgb(255, 24, 24);
  border: none;
  padding: 20px 60px;
  border-radius: 10px;
  outline: none;
  font-size: 22px;
  color: white;
  font-weight: bold;
  box-shadow: 5px 8px 15px rgb(255, 24, 24);
}
.startpage-button button:hover {
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -30%,
    rgb(255, 24, 24) 100%
  );
}

@media (max-width: 768px) {
  .startpage {
    max-width: 100vw;
    flex-direction: column;
  }
  .startpage-img {
    width: 100%;
  }
  .startpage-img img {
    width: 100%;
    object-fit: cover;
  }
  .startpage-text {
    width: 100%;
    margin-top: 40px;
  }
  .startpage-button button {
    padding: 20px 60px;
  }
}
@media (max-width: 425px) {
  .startpage-text h1 {
    font-size: 32px;
  }
}
@media (max-width: 375px) {
  .startpage {
    height: 100vh;
    margin: 0 auto;
  }
  .startpage-text {
    margin-top: 80px;
    height: 50%;
  }
  .startpage-text h1 {
    font-size: 32px;
  }
  .startpage-img {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
  }
  .startpage-img img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    margin-left: -15px;
    margin-right: 0;
  }
  .startpage-button button {
    padding: 10px 40px;
    font-size: 18px;
  }
}
@media (max-width: 375px) {
  .startpage-img {
    min-width: 375px;
  }
  .startpage-img img {
    height: 100%;
    width: auto;
    margin-top: -40px;
  }
}
