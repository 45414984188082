.table-costs{
    background-image: linear-gradient(to right, #1a227ef1, rgba(13, 16, 56, 0.9)),
    url("https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/bg-utils.svg?alt=media&token=f663cad6-9f36-420e-a3d3-f78197dc42a1");
    color: white;
    padding:  32px 0;
    display: flex;
    box-shadow: 5px 8px 15px #1a237e;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.table-costs__logo img{
    width: 128px;
    height: 128px;
    margin: 0 auto;
}
.table-costs__text{
    margin-top: 18px;
    text-align: center;
}
.table-costs ul{
    width: 100%;
}
.table-costs li:nth-child(2n+1){
    background-color: #0f1661c0;;
}
.table-costs ul{
    padding: 32px 0;
    margin: 0;
}
.table-costs ul li{
    list-style: none;
    padding: 10px 32px;
    margin: 0;
}
.table-costs p{
    font-weight: bold;
    font-size: 26px;
}
.table-costs p span{
    font-weight: normal;
    font-size: 22px;
}

@media(max-width: 425px){
    .table-costs p{
        font-size: 18px;
    }
    .table-costs p span{
        font-size: 16px;
    }
}