.container-options {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.titles h4 {
  font-size: 22px;
  background-color: #e1e1e1 !important;
  padding: 20px 15px;
  border-radius: 10px;
  font-weight: normal;
}
.options-email {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.options-email a img {
  height: 150px;
  width: 150px;
  margin-right: 0;
}
.options-email p {
  font-weight: bold;
  font-size: 22px;
  margin-top: 20px;
}
.submitHomework {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  color: #979da7;
}
.submitHomework h4 {
  font-weight: bold;
  font-size: 28px;
}

@media (max-width: 768px) {
  .options-email a img {
    height: 120px;
    width: 120px;
  }
  .options-email p {
    font-size: 18px;
  }
}
@media (max-width: 505px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 495px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 485px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 475px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 465px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 455px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 445px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 435px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 415px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 417px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 419px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 385px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .container-options {
    width: 100%;
  }
  .options-email a {
    display: flex;
    justify-content: center;
  }
  .options-email a img {
    margin: 0 auto;
    height: 60%;
    width: 60%;
  }
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
}
@media (max-width: 365px) {
  .options-email p {
    font-size: 13px;
  }
  .titles h4 {
    font-size: 13px;
  }
  .submitHomework h4 {
    font-size: 13px;
  }
}
@media (max-width: 355px) {
  .options-email p {
    font-size: 13px;
  }
  .titles h4 {
    font-size: 13px;
  }
  .submitHomework h4 {
    font-size: 13px;
  }
}
@media (max-width: 345px) {
  .options-email p {
    font-size: 12px;
  }
  .titles h4 {
    font-size: 12px;
  }
  .submitHomework h4 {
    font-size: 12px;
  }
}
@media (max-width: 320px) {
  .options-email p {
    font-size: 14px;
  }
  .titles h4 {
    font-size: 14px;
  }
  .submitHomework h4 {
    font-size: 14px;
  }
  .container-options {
    flex-direction: column-reverse;
  }
  .options-email a img {
    height: 125px;
    width: 125px;
    margin-top: 20px;
  }
}
