.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}
.profile-info {
  width: 90%;
  height: 100%;
  padding: 80px 0;
  background-image: linear-gradient(to right, #1a227ef1, rgba(13, 16, 56, 0.9)),
    url("https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/bg-utils.svg?alt=media&token=f663cad6-9f36-420e-a3d3-f78197dc42a1");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.profile-info__texts {
  margin-top: 120px;
  width: 80%;
  height: 100%;
}
.profile-info__texts span {
  font-weight: 300;
  font-size: 20px;
}
.profile-info__avatar {
  width: 200px;
  height: 200px;
}
.profile-info__avatar img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.4);
}
.profile-info__avatar-texts {
  color: white;
  text-align: center;
  margin-top: 10px;
}
.profile-info__avatar p {
  font-weight: bold;
  font-size: 22px;
}
.profile-info__avatar p span {
  margin-top: 0;
  font-weight: 300;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
}
.profile-info__group {
  display: flex;
  margin: 40px 0;
  justify-content: space-between;
  background-color: white;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.4);
  padding: 40px 60px;
  border-radius: 20px;
}
.profile-group__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-group__text p {
  font-weight: 300;
  font-size: 18px;
  color: #191c22c2;
}
.profile-info__group p span {
  font-weight: bold;
  font-size: 20px;
  color: #191c22;
}

.profile-img {
  height: 1051px;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -20%,
    rgb(255, 24, 24) 100%
  );
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px;
}

.profile-img img {
  width: 100%;
  height: 80%;
  object-fit: contain;
  margin: 0;
}
@media (max-width: 768px) {
  .profile {
    flex-direction: column;
    width: 95%;
  }
  .profile-img {
    height: 80px;
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0;
    order: -1;
  }

  .profile-info {
    height: 100%;
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

@media (max-width: 425px) {
  .profile-info__avatar {
    width: 120px;
    height: 120px;
  }
  .profile-info__avatar img {
    margin-bottom: 10px;
  }
  .profile-info__avatar p {
    font-size: 14px;
  }
  .profile-info__avatar p span {
    font-size: 14px;
  }
  .profile-info__texts {
    width: 95%;
    margin-top: 80px;
  }
  .profile-info__group {
    padding: 20px 10px;
    border-radius: 10px;
  }
  .profile-group__text p {
    font-size: 12px;
  }
  .profile-group__text p span {
    font-size: 14px;
  }
}
