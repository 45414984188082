.container-bio {
  display: flex;
  justify-content: center;
}
.biography-header {
  position: absolute;
  width: 1200px;
  height: 400px;
  margin: 0 auto;
  border-radius: 0 0 20px 20px;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.4);
}
.biography-header img {
  height: 100%;
  width: 100%;
  border-radius: 0 0 20px 20px;
  object-fit: cover;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.4);
}
.biography-header__contain {
  position: relative;
  width: 1200px;
}
.biography-header__contain-text {
  display: flex;
  justify-content: flex-end;
  padding: 20px 40px 10px 0;
  font-size: 28px;
  color: white;
}
.biography-header__contain-text-name{
  text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
}
.biography-header__contain-text p {
  font-weight: 300;
}
.biography-header__contain-text span {
  font-weight: 700;
}
.biography-header__contain-text-year p {
  font-size: 22px;
  float: right;
}
.biography-header__avatar {
  position: absolute;
  top: 290px;
  border-radius: 50%;
}
.biography-header__avatar img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.582);
  box-shadow: 10px 5px 20px 5px rgba(0, 0, 0, 0.4);
  object-fit: cover;
}
@media (max-width: 425px) {
  .biography-header {
    width: 425px;
    border-radius: 0;
  }
  .biography-header img {
    min-width: 100%;
    border-radius: 0;
  }
  .container-bio {
    margin-bottom: 50px;
  }
  .biography-header__avatar {
    left: 26%;
  }
  .biography-header__contain-text {
    padding: 20px 20px 20px;
  }
  .biography-header__contain-text p {
    font-size: 18px;
  }
}
