.footer-app {
  display: flex;
  background: linear-gradient(to right, #1a237e, rgb(13, 16, 56));
  justify-content: space-around;
  align-items: center;
  margin-top: 160px;
  border-top: 5px solid rgb(255, 24, 24);
  padding: 40px 0;
}

.footer-logo {
  display: flex;
  align-items: center;
}
.footer-logo img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.footer-logo p {
  color: white;
}
.footer-social img {
  height: 40px;
  width: 40px;
  margin-right: 40px;
}
.copyR {
  color: white;
}
@media (max-width: 768px) {
  .footer-app {
    height: 300px;
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-logo {
    flex-direction: column;
  }
  .footer-logo p {
    font-size: 18;
    margin-top: 10px;
  }
}
@media (max-width: 425px) {
  .footer-logo p {
    font-size: 14px;
  }
  .copyR {
    font-size: 14px;
  }
}
