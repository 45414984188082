.menubio {
  height: 100%;
  margin-top: 400px;
  margin-bottom: 80px;
}
.menubio-container {
  display: flex;
  flex-wrap: wrap;
}
.menubio-title {
  font-size: 28px;
  padding-bottom: 20px;
  color: #191c22;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.281);
}
.menubio-contain {
  /* display: flex;
  height: 150px;
  width: 23%;
  margin-right: 20px;
  border: 1px solid white;
  background-color: white;
  border-radius: 10px;
  margin-top: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.4);
  border: 1px solid #c8c9ca;
  position: relative;
  overflow: hidden; */
  width: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 200px;
  background: #e1e1e1;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  padding: 10px;
  border-radius: 20px;
  flex-direction: column;
}
.menubio-contain::before {
  content: "";
  position: absolute;
  width: 60px;
  background-image: linear-gradient(180deg, #1a237e, rgb(255, 24, 24));
  height: 130%;
  animation: rotBGimg 20s linear infinite;
  transition: all 0.2s linear;
}
@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.menubio-contain::after {
  content: "";
  position: absolute;
  background: #e1e1e1;
  inset: 4px;
  border-radius: 15px;
}
.menubio-animatio {
  z-index: 9999999;
}
.menubio-contain:hover {
  text-decoration: none;
}
.menubio-contain:active {
  text-decoration: none;
}
.menubio-contain__icon img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.menubio-contain__text {
  position: relative;
  margin-top: 20px;
  text-align: center;
  color: #191c22;
  z-index: 999;
}
.menubio-contain__notification {
  position: absolute;
  background-color: red;
  color: white;
  font-size: 11px;
  border-radius: 5px;
  padding: 3px 5px;
  top: -50px;
  right: 0px;
}
.menubio-contain__text p {
  font-weight: bold;
  font-size: 16px;
}
.menubio-contain__text span {
  font-weight: 300;
  font-size: 14px;
}
@media (max-width: 768px) {
  .menubio-contain {
    height: 180px;
    width: 180px;
    padding: 10px;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: transparent;
  }
  .menubio-contain__notification {
    top: -125px;
    right: -10px;
    font-size: 9px;
  }
  .menubio-contain__icon img {
    width: 50px;
    height: 50px;
    margin-right: 0;
    z-index: 9999999;
  }
  .menubio-contain__text p {
    font-size: 14px;
  }
  .menubio-contain__text span {
    font-size: 14px;
  }
}
/* @media (max-width: 768px) {
  .menubio-container{
    justify-content: space-evenly;
  }
}
@media (max-width: 425px) {
  .menubio-contain {
    height: 120px;
    width: 150px;
  }
  .menubio-contain__icon img {
    width: 40px;
    height: 40px;
    margin-right: 0;
  }
  .menubio-contain__text p span{
    display: none;
  }
}
@media (max-width: 375px) {
  .menubio-contain {
    height: 120px;
    width: 40%;
  }
}
@media (max-width: 320px) {
  .menubio-contain {
    height: 120px;
    width: 40%;
  }
} */
.menu-title__mobile {
  display: none;
}
@media (max-width: 1024px) {
  /* .menubio-container{
    justify-content: center;
  } */
  .menubio-contain {
    width: 30%;
  }
}
@media (max-width: 768px) {
  .menubio-container {
    justify-content: space-evenly;
  }
  .menu-title__mobile {
    display: block;
  }
  .menubio-title {
    display: none;
  }
  .menubio-container {
    position: fixed;
    z-index: 9999999;
    bottom: 0;
    background-image: linear-gradient(to right, #1a227e, rgba(13, 16, 56, 1));
    border-top: 3px solid rgb(255, 24, 24);
    left: 0;
    right: 0;
  }
  .menubio-contain__text {
    display: none;
  }
  .menubio-contain {
    height: 60px;
    width: 80px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 40px;
    height: 40px;
    margin: 0;
  }
  .menubio-contain__text p {
    display: none;
  }
}
@media (max-width: 425px) {
  .menubio-contain {
    height: 45px;
    width: 45px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
.menubio-contain__icon {
  z-index: 999 !important;
}

@media (max-width: 445px) {
  .menubio-contain {
    height: 45px;
    width: 45px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
@media (max-width: 455px) {
  .menubio-contain {
    height: 45px;
    width: 45px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
@media (max-width: 465px) {
  .menubio-contain {
    height: 45px;
    width: 45px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
@media (max-width: 475px) {
  .menubio-contain {
    height: 45px;
    width: 45px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
@media (max-width: 485px) {
  .menubio-contain {
    height: 45px;
    width: 45px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
@media (max-width: 495px) {
  .menubio-contain {
    height: 45px;
    width: 45px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
@media (max-width: 505px) {
  .menubio-contain {
    height: 45px;
    width: 45px;
    margin-bottom: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}

@media (max-width: 385px) {
  .menubio-contain {
    height: 45px;
    width: 24%;
    margin-bottom: 5px;
    display: flex;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
@media (max-width: 375px) {
  .menubio-contain {
    height: 45px;
    width: 24%;
    margin-bottom: 5px;
    display: flex;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .menubio-contain__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menubio-contain__icon img {
    width: 32px;
    height: 32px;
    margin: 0;
  }
}
