h2 {
  font-size: 38px;
  color: #191c22;
  margin: 80px 0 0 0;
  font-weight: bold;
}
h3 {
  font-size: 28px;
  color: #191c22;
  font-weight: bold;
  margin: 80px 0 20px 0;
}
.carousel-item img {
  border-radius: 10px;
}
.card-body {
  height: 100%;
  width: 100%;
}
.card-body img {
  height: 100%;
  width: 100%;
}
