.btn-link {
  color: #1a237e !important;
  font-size: 18px;
}

.cards-alineacion {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.container-cards-calificaciones {
  width: 30%;
  height: 100%;
  box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.4);
  margin-right: 20px;
  margin-bottom: 40px;
  border-radius: 10px;
  border: 1px solid #c8c9ca;
}
.card-calificacion__bg {
  height: 200px;
  width: 100%;
}
.card-calificacion__bg img {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
}
.card-calificacion__note {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.card-calificacion__note p {
  font-size: 18px;
  color: #191c22;
  margin: 20px 0;
}
.card-calificacion__note button {
  border: none;
  padding: 5px 15px;
  box-shadow: 5px 8px 15px rgb(255, 24, 24);
  background-color: rgb(255, 24, 24);
  border-radius: 5px;
}
.card-calificacion__note button:hover {
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -30%,
    rgb(255, 24, 24) 100%
  );
}
.card-calificacion__note button a {
  color: white;
}
.card-calificacion__note button a:hover {
  text-decoration: none;
}
.card-calificacion__note p span {
  font-weight: bold;
}
@media (max-width: 768px) {
  .container-cards-calificaciones {
    width: 45%;
  }
}
@media (max-width: 425px) {
  .container-cards-calificaciones {
    width: 100%;
  }
}
