.task-container {
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #9791a0;
  border-radius: 5px;
}
.task-img {
  height: 80px;
  width: 15%;
}
.task-img img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.task-subject {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 10%;
}
.task-content {
  height: 80px;
  width: 20%;
}
.task-delivery {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 20%;
}
.button-edit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-edit button {
  border: none;
  background: #1a237e;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 5px 8px 10px #1a237e;
}
.button-edit button:hover {
  background: linear-gradient(to right, #434dbd -30%, rgb(23, 28, 99) 100%);
}
.button-delete {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-delete button {
  border: none;
  background: rgb(255, 24, 24);
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 5px 8px 10px rgb(255, 24, 24);
}
.button-delete button:hover {
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -30%,
    rgb(255, 24, 24) 100%
  );
}
.tasks-professor__title {
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9791a0;
  flex-direction: column;
}
.tasks-professor__title h4 span {
  font-size: 20px;
}
.btn-searchTask {
  text-decoration: none;
  position: absolute;
  border: none;
  font-size: 14px;
  font-family: inherit;
  color: #fff;
  width: 9em;
  height: 3em;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(
    90deg,
    #434dbd,
    #1a237e,
    #323db1,
    rgb(255, 24, 24)
  );
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
  outline: none !important;
}
.btn-searchTask:hover {
  animation: ani 8s linear infinite;
  border: none;
}
@keyframes ani {
  0% {
    background-position: 0%;
  }
}
.btn-searchTask:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(
    90deg,
    #434dbd,
    #1a237e,
    #323db1,
    rgb(255, 24, 24)
  );
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
}

.btn-searchTask:hover::before {
  filter: blur(20px);
}

.btn-searchTask:active {
  background: linear-gradient(
    90deg,
    #434dbd,
    #1a237e,
    #323db1,
    rgb(255, 24, 24)
  );
}

.task-selected-container {
  width: 100%;
}
.task-selected-container h2 {
  text-align: center;
  margin-bottom: 6px !important;
}
.task-selected-container p {
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
}
.task-selected-video {
  display: flex;
  justify-content: center;
}
.task-selected-container p span {
  font-weight: bold;
}
.task-selected-url {
  margin-top: 20px;
  width: 100%;
}
.task-selected-url a {
  font-size: 18px;
  color: #1a237e;
}
.task-selected-fecha p {
  font-weight: bold;
}
.videos-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.task-selected-video {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 45%;
}
.task-selected-fecha p span {
  color: rgb(255, 24, 24);
  font-weight: normal;
}
.url-zoom-container {
  padding-top: 20px;
  border-top: 1px solid #2f3fec79;
  margin-top: 40px;
  text-align: left;
}
.url-zoom-container p {
  text-align: left;
}
.container-checked {
  display: flex;
  align-items: center;
}
.container-checked P {
  font-weight: bold;
  margin-right: 10px;
}
.task-selected-subject {
  background-image: linear-gradient(to right, #1a227ef1, rgba(13, 16, 56, 0.9));
  color: white;
  padding: 10px 0;
  border-radius: 10px;
}
.task-selected-content {
  padding: 20px 0;
}
.task-selected-bg {
  background-color: #e1e1e1;
  border-radius: 10px;
  padding: 10px 0;
}
@media (max-width: 425px) {
  .tasks-professor__title h4 {
    font-size: 16px;
  }
  .videos-container video {
    width: 400px;
  }
  .url-zoom-container p {
    font-size: 18px;
  }
  .videos-container {
    flex-direction: column;
  }
  .task-selected-video {
    width: 400px;
  }
  .task-selected-fecha p {
    font-size: 18px;
  }
}
.container-file__edit {
  display: flex;
  flex-direction: column;
}
.archive-prev {
  color: #000000;
  font-size: 18px;
  text-align: center;
  width: 100%;
}
.archive-prev:hover {
  color: #000000;
}
