.login-bg {
  /* background: linear-gradient(to right, #1a237e, rgb(13, 16, 56)); */
  background: linear-gradient(to right, #1a237e, rgb(13, 16, 56));
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.1);
  height: 700px;
  width: 500px;
  border: 2px solid white;
  border-radius: 40px;
  padding: 80px 40px;
}
.login-box__text h3 {
  color: white;
  text-align: center;
  margin-top: 40px;
}
.login-box__inputs {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
form {
  caret-color: white;
}
.login-box__inputs input {
  height: 50px;
  margin-bottom: 20px;
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 2px solid white;
  padding: 0 20px;
  outline: none;
  font-size: 16px;
  color: white;
}
.login-box__inputs input::placeholder {
  color: rgba(255, 255, 255, 0.685);
}
.login-box__inputs input:focus {
  border-bottom: 2px solid rgba(255, 24, 24, 0.6);
}

.login-box__button button {
  margin-top: 40px;
  width: 100%;
  border: none;
  background: rgb(255, 24, 24);
  padding: 15px 10px;
  border-radius: 20px;
  outline: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 5px 5px 8px rgb(255, 24, 24, .5);
}
.login-box__button button:hover {
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -30%,
    rgb(255, 24, 24) 100%
  );
}
.see-password{
  width:100%;
  border: none;
  background: transparent;
  color: white;
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  outline: none !important;
}

form a {
  color: white;
  font-size: 12px;
  border-bottom: 1px solid white;
  float: right;
  margin-top: 10px;
}
form a:hover {
  color: white;
  border-bottom: 1px solid rgb(255, 24, 24);
  text-decoration: none;
}

.footer-login {
  background: linear-gradient(to right, #1a237e, rgb(13, 16, 56));
  padding: 40px 0;
  display: flex;
  justify-content: space-around;
}
.footer-login p {
  color: white;
}
.footer-login a:hover {
  text-decoration-color: rgb(255, 24, 24);
}
.alert-danger {
  background: rgba(255, 24, 24, 0.842);
  padding: 15px;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 425px) {
  .login-box {
    width: 95%;
    border-radius: 20px;
    padding: 20px;
  }
  .footer-login p {
    font-size: 12px;
  }
}
