.platform-container {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
.platform-card {
  width: 45%;
  height: 100%;
  border: 1px solid rgb(196, 193, 193);
  border-radius: 10px;
  padding: 0 0 50px 0;
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.4);
}
.platform-card__img img {
  width: 100%;
  height: 250px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 50px;
  object-fit: cover;
}
.platform-card__texts {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.platform-card__texts-button a {
  font-weight: bold;
  background-color: rgb(255, 24, 24);
  padding: 10px 80px;
  border-radius: 5px;
  color: white;
  font-size: 22px;
  box-shadow: 5px 8px 15px rgb(255, 24, 24);
}
.platform-card__texts-button a:hover {
  background: linear-gradient(
    to right,
    rgb(250, 92, 92) -30%,
    rgb(255, 24, 24) 100%
  );
  text-decoration: none;
}
.platform-card__links-container {
  width: 100%;
}
.platform-card__links {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.platform-card__links p {
  font-weight: bold;
}
.audio-help {
  margin-top: 40px;
  text-align: center;
  font-size: 18px;
}
.platform-card__links a {
  color: white;
  padding: 5px 15px;
  background-color: #182072;
  font-size: 16px;
  box-shadow: 5px 8px 15px #182072;
}
.platform-card__links a:hover {
  background: linear-gradient(to right, #434dbd -30%, rgb(23, 28, 99) 100%);
}
@media (min-width: 500px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media (max-width: 505px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}
@media (max-width: 475px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 495px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}
@media (max-width: 475px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 485px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}

@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}
@media (max-width: 465px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}
@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}
@media (max-width: 445px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}
@media (max-width: 435px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}
@media (max-width: 425px) {
  .platform-container {
    flex-direction: column;
  }
  .platform-card {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 320px) {
  .platform-card__texts-button a {
    font-size: 18px;
    padding: 10px 40px;
  }
}
